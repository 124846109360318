// Button.tsx

import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as GoogleLogo } from "../../assets/google-logo.svg";
import Colors from "../../constants/Colors";

const BaseButton = styled.button`
  font-weight: 600;
  border: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmailButton = styled(BaseButton)`
  min-width: 276px;
  height: 60px;
  font-size: 16px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.disabled ? "#cccccc" : "#fcaf17"}; // Greyed out when disabled
  color: ${(props) =>
    props.disabled ? "#666666" : "white"}; // Dim text color when disabled
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${(props) =>
      props.disabled ? "#cccccc" : "#fbbc05"}; // No hover effect when disabled
    // If you want to keep the hover effect regardless of the disabled state, remove this line
  }
`;

const GoogleButton = styled(BaseButton)`
  min-width: 266px;
  height: 60px;
  font-size: 16px;
  border: 2px solid #e2e2e2;
  border-radius: 30px;
  background-color: #ffffff;
  color: #3a3a3a;

  &:hover {
    background-color: #fafafa;
    border-color: #dedede;
  }
`;

const ReversedButton = styled(BaseButton)`
  min-width: 266px;
  height: 60px;
  font-size: 16px;
  color: ${Colors.primary};
  border-radius: 30px;
  background-color: #ffffff;
  border: 2px solid ${Colors.primary};
`;

const LowkeyButton = styled(BaseButton)`
  min-width: 196px;
  height: 45px;
  color: ${Colors.primary};
  font-size: 18px;
  background-color: ${Colors.orangeLight};
`;

const TrackProductButton = styled(BaseButton)`
  font-size: 18px;
  font-family: "Poppins";
  width: 200px;
  background-color: ${(props) =>
    props.disabled ? "#ccc" : "#fcaf17"}; // Gray when disabled
  border: none;
  border-radius: 6px;
  color: ${(props) =>
    props.disabled ? "#888" : "#ffffff"}; // Lighter text when disabled
  cursor: ${(props) =>
    props.disabled ? "not-allowed" : "pointer"}; // Change cursor when disabled

  &:hover {
    background-color: ${(props) =>
      props.disabled ? "#ccc" : "#fbbc05"}; // No hover effect when disabled
  }

  @media (max-width: 1500px) {
    margin: auto;
    padding: 15px;
  }
`;

const SubscriptionButton = styled(BaseButton)`
  min-width: 220px;
  min-height: 50px;
  font-weight: 600;
`;

const RightIconContainer = styled.div`
  position: absolute;
  right: 38px;
`;

const Loader = styled.span`
  display: inline-block;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

type HTMLButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

interface IButton extends Omit<HTMLButtonProps, "type"> {
  isLoading?: boolean;
  type?:
    | "email"
    | "google"
    | "reversed"
    | "lowkey"
    | "subscription"
    | "track-product";
  onClick?: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const Button = ({
  type,
  onClick,
  children,
  style,
  isLoading = false,
  ...rest
}: IButton) => {
  const InnerContent = () => {
    if (isLoading)
      return (
        <Loader>
          <FontAwesomeIcon icon={faSpinner} />
        </Loader>
      );
    return (
      <>
        {children}
        <RightIconContainer>
          <FontAwesomeIcon icon={faArrowRight} />
        </RightIconContainer>
      </>
    );
  };

  switch (type) {
    case "email":
      return (
        <EmailButton onClick={onClick} style={{ ...style }} {...rest}>
          <InnerContent />
        </EmailButton>
      );
    case "google":
      return (
        <GoogleButton onClick={onClick} style={{ ...style }} {...rest}>
          <GoogleLogo width="20px" style={{ marginRight: "11px" }} />
          {isLoading ? (
            <Loader>
              <FontAwesomeIcon icon={faSpinner} />
            </Loader>
          ) : (
            children
          )}
        </GoogleButton>
      );
    case "reversed":
      return (
        <ReversedButton onClick={onClick} style={{ ...style }} {...rest}>
          {isLoading ? (
            <Loader>
              <FontAwesomeIcon icon={faSpinner} />
            </Loader>
          ) : (
            children
          )}
        </ReversedButton>
      );
    case "lowkey":
      return (
        <LowkeyButton onClick={onClick} style={{ ...style }} {...rest}>
          {isLoading ? (
            <Loader>
              <FontAwesomeIcon icon={faSpinner} />
            </Loader>
          ) : (
            children
          )}
        </LowkeyButton>
      );
    case "subscription":
      return (
        <SubscriptionButton onClick={onClick} style={{ ...style }} {...rest}>
          {isLoading ? (
            <Loader>
              <FontAwesomeIcon icon={faSpinner} />
            </Loader>
          ) : (
            children
          )}
        </SubscriptionButton>
      );
    case "track-product":
      return (
        <TrackProductButton onClick={onClick} style={style} {...rest}>
          {isLoading ? (
            <Loader>
              <FontAwesomeIcon icon={faSpinner} />
            </Loader>
          ) : (
            children
          )}
        </TrackProductButton>
      );
    default:
      return (
        <button onClick={onClick} style={{ ...style }} {...rest}>
          {isLoading ? (
            <Loader>
              <FontAwesomeIcon icon={faSpinner} />
            </Loader>
          ) : (
            children
          )}
        </button>
      );
  }
};

export default Button;
