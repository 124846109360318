import React from "react";
import SignPageContainer from "../components/authenticationComponents/subComponents/SignFormContainer";
import VerifyEmailForm from "../components/authenticationComponents/VerifyEmailForm";

const VerifyEmailPage = () => {
  return (
    <SignPageContainer>
      <VerifyEmailForm />
    </SignPageContainer>
  );
};

export default VerifyEmailPage;
