import React, { useState } from "react";
import styled from "styled-components";
import { ITrackedItem } from "../../../contexts/HomeContext";

interface ProductDetailsProps {
  trackedItemData: ITrackedItem;
}

const ProductDetailsContainer = styled.div`
  background-color: #ffffff;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
  width: 100%;
  max-width: 399px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`;

const sizes = {
  desktop: 1501,
  mobile: 1500,
};

const media = {
  desktop: `(min-width: ${sizes.desktop}px)`,
  mobile: `(max-width: ${sizes.mobile}px)`,
};

const ProductDetailContent = styled.div<{ $showDetailsActive: boolean }>`
  display: ${(props) => (props.$showDetailsActive ? "block" : "none")};

  @media ${media.desktop} {
    display: block;
  }
`;

const DetailsTitle = styled.p`
  text-align: center;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  display: block;
`;

const DetailsTable = styled.table`
  width: 200px;
  border-collapse: collapse;
  display: table;
`;

const TableRow = styled.tr``;

const TableCell = styled.td`
  font-size: 16px;
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
`;

const OrangeLine = styled(TableRow)`
  width: 70%;
  border-bottom: 2px solid #fcaf17;
`;

const SavingsRow = styled(TableRow)`
  font-weight: bold;
`;

const Button = styled.button`
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  cursor: pointer;
  width: 156px;
  height: 42px;
  background-color: #feecc8;
  border: none;
  border-radius: 2px;
  color: #fcaf17;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShowDetailsButton = styled(Button)`
  @media ${media.desktop} {
    display: none;
  }
}`;

const ProductDetails: React.FC<ProductDetailsProps> = ({ trackedItemData }) => {
  const [showDetailsActive, setShowDetailsActive] = useState(false);

  function parsePrice(priceStr: string) {
    // Replace periods with nothing and commas with decimal points
    const parsedPriceStr = priceStr.replace(".", "").replace(",", ".");

    // Convert the string to a float
    return parseFloat(parsedPriceStr);
  }

  const previousPriceNum = parsePrice(trackedItemData.previous_price);
  const currentPriceNum = parsePrice(trackedItemData.current_price);
  const savings = previousPriceNum - currentPriceNum;

  return (
    <ProductDetailsContainer id="PRODUCT DETAILS CONTAINER">
      <ShowDetailsButton
        onClick={() => setShowDetailsActive(!showDetailsActive)}
      >
        {showDetailsActive ? "Hide details" : "Show details"}
      </ShowDetailsButton>

      <ProductDetailContent $showDetailsActive={showDetailsActive}>
        <DetailsTitle>Track Details</DetailsTitle>
        <DetailsTable>
          <TableRow>
            <TableCell>Initial Price</TableCell>
            <TableCell style={{ textAlign: "left" }}>
              {trackedItemData.currency_sign}
              {trackedItemData?.previous_price}
            </TableCell>
          </TableRow>
          <OrangeLine>
            <TableCell>Current Price</TableCell>
            <TableCell style={{ textAlign: "left" }}>
              {trackedItemData.currency_sign}
              {trackedItemData?.current_price}
            </TableCell>
          </OrangeLine>
          <SavingsRow>
            <TableCell>You saving</TableCell>
            <TableCell style={{ textAlign: "left" }}>
              {trackedItemData.currency_sign}
              {savings}
            </TableCell>
          </SavingsRow>
        </DetailsTable>
      </ProductDetailContent>
    </ProductDetailsContainer>
  );
};

export default ProductDetails;
