// backend_api.ts

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// backend_api.ts
export const addProduct = async (
  user_uid: string,
  product_url: string,
  price_threshold: number
) => {
  try {
    console.log('Sending request to backend'); // Debugging line
    const response = await fetch(`${BACKEND_URL}/api/add-product`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_uid: user_uid,
        product_url: product_url,
        price_threshold: price_threshold,
      }),
    });
  } catch (error) {
    console.error('Error in addProduct:', error); // Debugging line
    throw error;
  }
};

export const deleteProduct = async (user_uid: string, product_id: string) => {
  try {
    console.log(`Deleting product ${product_id} for user ${user_uid}`); // Log the action details

    const response = await fetch(`${BACKEND_URL}/api/delete-product`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_uid: user_uid,
        product_id: product_id,
      }),
    });

    console.log('Response status:', response.status, 'ok?', response.ok); // Log status and ok flag of response

    if (!response.ok) {
      const responseData = await response.json();
      console.log('Error response data:', responseData); // Log response data for failed request
      throw new Error(responseData.detail || 'Failed to delete product.');
    }

    const jsonData = await response.json();

    console.log('Success:', jsonData); // Log JSON response data for successful request

    return jsonData;
  } catch (error) {
    console.error('Caught error:', error); // Log any caught errors
    throw error;
  }
};

export const createPaymentIntent = async (
  user_uid: string,
  amount: number,
  currency: string = 'usd'
) => {
  try {
    const response = await fetch(`${BACKEND_URL}/api/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_uid, amount, currency }), // Include user_uid here
    });
    console.log('response from the server', response);

    if (!response.ok) {
      throw new Error('Failed to create PaymentIntent');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in createPaymentIntent:', error);
    throw error;
  }
};

export const confirmPaymentIntent = async (
  payment_intent_id: string,
  payment_method_id: string
) => {
  try {
    const response = await fetch(`${BACKEND_URL}/api/confirm-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ payment_intent_id, payment_method_id }),
    });

    if (!response.ok) {
      throw new Error('Failed to confirm PaymentIntent');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in confirmPaymentIntent:', error);
    throw error;
  }
};

export const markUserAsPaid = async (user_uid: string) => {
  try {
    console.log(`Marking user ${user_uid} as paid`); // Log the action details

    const response = await fetch(`${BACKEND_URL}/api/mark-user-as-paid`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: user_uid,
    });

    console.log('Response status:', response.status, 'ok?', response.ok); // Log status and ok flag of response

    if (!response.ok) {
      const responseData = await response.json();
      console.log('Error response data:', responseData); // Log response data for failed request
      throw new Error(responseData.detail || 'Failed to mark user as paid.');
    }

    const jsonData = await response.json();

    console.log('Success:', jsonData); // Log JSON response data for successful request

    return jsonData;
  } catch (error) {
    console.error('Caught error:', error); // Log any caught errors
    throw error;
  }
};

export const checkPaymentStatus = async (paymentIntentId: string) => {
  try {
    const response = await fetch(
      `${BACKEND_URL}/api/check-payment-status?payment_intent_id=${paymentIntentId}`
    );
    const data = await response.json();
    return data.status === 'succeeded';
  } catch (error) {
    console.error('Error in checkPaymentStatus:', error);
    return false;
  }
};
