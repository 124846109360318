// SignUpPage.tsx

import React from "react";
import SignPageContainer from "../components/authenticationComponents/subComponents/SignFormContainer";
import SignUpForm from "../components/authenticationComponents/SignUpForm";

const SignUpPage = () => {
  return (
    <SignPageContainer>
      <SignUpForm />
    </SignPageContainer>
  );
};

export default SignUpPage;
