import React, {
  useState,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
} from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";

interface ITextField extends InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  type?: HTMLInputTypeAttribute;
  error?: boolean;
  errorMessage?: string;
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
`;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean | undefined;
}

const Input = styled.input<InputProps>`
  border: 2px solid ${(props) => (props.error ? "red" : "#dedede")};
  border-radius: 10px;
  padding: 19px 31px;
  background-color: #fafafa;
  font-size: 16px;
  width: 100%;

  &:focus {
    outline: none;
    background-color: #ffffff;
    color: #707070;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 54%;
  transform: translateY(-50%);
  right: 34px;
  cursor: pointer;
`;

const WarningDiv = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 2px;
  margin-left: 15px;
  position: absolute;
`;

const ICON_WIDTH = 20;

const TextField = (props: ITextField) => {
  const { type, style, error, errorMessage, ...restProps } = props;

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Wrapper style={{ ...style }}>
      <InputWrapper>
        <Input
          type={showPassword ? "text" : type}
          error={error}
          {...restProps}
        />
        {type === "password" && props?.value?.length !== 0 && (
          <IconContainer onClick={toggleShowPassword}>
            {showPassword ? (
              <Icon icon="ph:eye" width={ICON_WIDTH} />
            ) : (
              <Icon icon="ph:eye-slash" width={ICON_WIDTH} />
            )}
          </IconContainer>
        )}
      </InputWrapper>
      <WarningDiv> {error ? errorMessage : ""} </WarningDiv>
    </Wrapper>
  );
};

export default TextField;
