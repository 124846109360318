import React from "react";
import SignFormPaper from "./subComponents/SignFormPaper";
import SignFormTitle from "./subComponents/SignFormTitle";
import Button from "../commonComponents/Button";
import Or from "./subComponents/Or";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { useAuth } from "../../contexts/AuthContext";
import SignFormSubtitle from "./subComponents/SignFormSubtitle";

const SignUpForm = () => {
  const navigate = useNavigate();
  const { signUpWithGoogle, signUpWithApple } = useAuth();
  const { raiseSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const handleSignUpWithGoogle = async () => {
    try {
      await signUpWithGoogle();
      navigate("/");
    } catch (error) {
      // Handle errors here
      setLoading(false);
    }
  };

  const handleSignUpWithApple = async () => {
    try {
      await signUpWithApple();
      navigate("/");
    } catch (error) {
      // Handle errors here
      setLoading(false);
    }
  };

  return (
    <SignFormPaper>
      <SignFormTitle> Sign Up </SignFormTitle>
      <SignFormSubtitle type="sign-up" />
      <Button type="google" onClick={handleSignUpWithGoogle} disabled={loading}>
        Sign up with Google
      </Button>
      {/* <Or /> */}
      {/* <Button type="apple" onClick={handleSignUpWithApple} disabled={loading}>
        Sign up with Apple
      </Button> */}
    </SignFormPaper>
  );
};

export default SignUpForm;
