import React, { useState, useEffect } from "react";
import SignFormPaper from "./subComponents/SignFormPaper";
import SignFormTitle from "./subComponents/SignFormTitle";
import {
  SubTitle,
  SubTitleLight,
  LinkTextLight,
} from "./subComponents/SignFormSubtitle";
import Button from "../commonComponents/Button";
import { ReactComponent as VerifyEmailLogo } from "../../assets/verify-email-icon.svg";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../contexts/SnackbarContext";

const VerifyEmailForm = () => {
  const { raiseSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { currentUser, sendVerificationEmail } = useAuth();

  useEffect(() => {
    if (currentUser.emailVerified) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const checkForVerifiedInterval = setInterval(() => {
      currentUser.reload().then(() => {
        if (currentUser.emailVerified) {
          navigate("/");
          clearInterval(checkForVerifiedInterval);
        }
      });
    }, 1000);

    return () => {
      clearInterval(checkForVerifiedInterval);
    };
  }, []);

  const resendVerificationEmail = async () => {
    try {
      await sendVerificationEmail();
      raiseSnackbar(
        "success",
        "Email sent.",
        {
          vertical: "top",
          horizontal: "center",
        },
        5000
      );
    } catch (error) {
      raiseSnackbar(
        "warning",
        "Something went wrong.",
        {
          vertical: "top",
          horizontal: "center",
        },
        5000
      );
    }
  };

  return (
    <SignFormPaper>
      <SignFormTitle style={{ marginBottom: "23px" }}>
        Verify your email
      </SignFormTitle>
      <SubTitleLight style={{ marginBottom: "45px", width: "380px" }}>
        Check<LinkTextLight>{currentUser.email}</LinkTextLight> for an email
        from us to complete this process.
      </SubTitleLight>
      <VerifyEmailLogo style={{ marginBottom: "29px" }} />
      <SubTitle style={{ marginBottom: "17px" }}>
        Waiting you to verify
      </SubTitle>
      <SubTitleLight style={{ marginBottom: "33px" }}>
        haven't got any email from us? <br /> click the resend
        below
      </SubTitleLight>
      <Button type="reversed" onClick={resendVerificationEmail}>
        Resend verification email
      </Button>
    </SignFormPaper>
  );
};

export default VerifyEmailForm;
