// TrackNewProductForm.tsx

import { useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { addProduct } from '../../backend_api';
import { useAuth } from '../../contexts/AuthContext';
import { useHome } from '../../contexts/HomeContext';
import Button from '../commonComponents/Button';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;

  @media (max-width: 1500px) {
    flex-direction: column;
  }
`;

const Input = styled.input`
  font-size: 18px;
  font-family: 'Poppins';
  width: calc(100% - 220px);
  height: 70px;
  color: #999999;
  padding-top: 21px;
  padding-bottom: 24px;
  padding-left: 47px;
  border: none;
  border-radius: 6px;

  @media (max-width: 1500px) {
    width: 100%;
    padding-top: 21px;
    padding-bottom: 24px;
    padding-left: 47px;
    margin-bottom: 15px;
  }
`;

const TrackNewProductForm = () => {
  const { raiseSnackbar } = useSnackbar();
  const { currentUser, isPaid } = useAuth();
  const { fetchTrackedItems, trackedItems } = useHome();
  const [isLoading, setIsLoading] = useState(false);

  const [url, setUrl] = useState('');
  const priceThreshold = 0; // replace this with your actual price threshold

  const addNewItem = async () => {
    try {
      console.log('User UID:', currentUser.uid); // Added logging
      console.log('URL:', url); // Added logging
      console.log('Price Threshold:', priceThreshold); // Added logging

      setIsLoading(true);
      await addProduct(currentUser.uid, url, priceThreshold);
      await fetchTrackedItems();
      setUrl('');
      raiseSnackbar('success', 'Item added successfully!', {
        vertical: 'top',
        horizontal: 'center',
      });
    } catch (error) {
      console.error('Error while adding product:', error); // Added logging
      if (error instanceof Error) {
        raiseSnackbar('error', error.message, {
          vertical: 'top',
          horizontal: 'center',
        });
      }
    }
    setIsLoading(false);
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  return (
    <Wrapper>
      <Input
        placeholder="Paste the amazon link here"
        value={url}
        onChange={handleUrlChange}
      />
      <Button
        type="track-product"
        onClick={addNewItem}
        isLoading={isLoading}
        disabled={
          trackedItems.length >= (isPaid ? 200 : 5) || !url || isLoading
        }
      >
        Track Product ({trackedItems.length}/{isPaid ? 200 : 5})
      </Button>
    </Wrapper>
  );
};

export default TrackNewProductForm;
