// ProductInfo.tsx

import styled from "styled-components";
import { ITrackedItem } from "../../../contexts/HomeContext";
import { deleteProduct } from "../../../backend_api";
import { useAuth } from "../../../contexts/AuthContext";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { useHome } from "../../../contexts/HomeContext";

const sizes = {
  desktop: 1501,
  mobile: 1500,
};

const media = {
  desktop: `(min-width: ${sizes.desktop}px)`,
  mobile: `(max-width: ${sizes.mobile}px)`,
};

/*  *********************
    0. SUB COMPONENTS
 *********************** */

// TOP (LEFT)

const ProductImage = styled.img`
  width: 50%;
  max-width: 210px;

  @media ${media.desktop} {
    width: 264px;
    margin: auto;
  }
`;

const TopContainerMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;

  @media ${media.desktop} {
    display: none;
  }
`;

const DecreasedPriceBox = styled.span`
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  background-color: #ffd2d2;
  width: 110px;
  height: 20px;
  color: #ff6565;
  // position: relative;
  // top: 5px;
  // left: 8px;

  @media ${media.desktop} {
    display: none;
  }
`;

const TrackTimeBoxMobile = styled.span`
  background-color: #ffeac1;
  color: #f09610;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  padding: 6px 16px;
  // position: absolute;
  // top: 16px;
  // right: 8px;
  border-radius: 2px;

  color: #aa6f16;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.846px;

  @media ${media.desktop} {
    display: none;
  }
`;

const TrackTimeBoxDesktop = styled.span`
  background-color: #ffeac1;
  color: #f09610;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  padding: 6px 16px;
  position: absolute;
  bottom: 15px;
  right: 15px;

  @media ${media.mobile} {
    display: none;
  }
`;

const ThreeDotsIcon = styled.img`
  position: absolute;
  right: 19px;
  top: -5px;
  cursor: pointer;
  display: none;
`;

const DeleteProductButton = styled.button`
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
  cursor: pointer;
  width: 184px;
  height: 50px;
  border: 2px solid #fcaf17;
  border-radius: 2px;
  color: #fcaf17;
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${media.mobile} {
    display: none;
  }

  @media ${media.desktop} {
    position: absolute;
    bottom: 0;
`;

const CrossIcon = styled.img`
  margin-right: 10px;
`;

// BOTTOM (RIGHT)

const Link = styled.a`
  text-decoration: none; // to remove the default underline of anchor tags
  color: inherit; // to inherit the color from the parent (ProductTitle)
`;

const ProductTitle = styled.div`
  margin-top: 0;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  text-align: center;

  @media ${media.desktop} {
    margin-bottom: 0px;
    text-align: left;
  }
`;

const ProductDescription = styled.div`
  font-size: 16px;
  font-family: "Poppins";
  display: none;

  @media ${media.desktop} {
    margin-top: 5px;
    margin-bottom: 10px;
    display: inline-block;
  }
`;

/*  *********************
    1. LAYOUT COMPONENTS 
*********************** */

const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 13px 21px;
  background-color: #ffffff;
  border-radius: 2px;
  width: 100%;
  max-width: 399px;
  position: relative;

  @media ${media.desktop} {
    max-width: 100%;
    width: calc(100% - 400px);
    padding: 45px 58px;
    flex-direction: row;
    gap: 45px;
  }
`;

// TOP (default - on mobile), LEFT (on desktop)
const ProductInfoTop = styled.div`
  display: flex;
  justify-content: center;
`;

const ProductInfoBottom = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const DecreasedPriceBoxDesktop = styled.span`
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  background-color: #ffd2d2;
  width: auto;
  padding: 2px 5px 2px 0px;
  color: #ff6565;
  position: absolute;
  top: 18px;
  left: 21px;

  @media ${media.mobile} {
    display: none;
  }
`;

interface IProductInfo {
  trackedItemData: ITrackedItem;
}

const ProductInfo = ({ trackedItemData }: IProductInfo) => {
  const { currentUser } = useAuth();
  const { raiseSnackbar } = useSnackbar();
  const { fetchTrackedItems } = useHome();

  function parsePrice(priceStr: string) {
    // Replace periods with nothing and commas with decimal points
    const parsedPriceStr = priceStr.replace(".", "").replace(",", ".");

    // Convert the string to a float
    return parseFloat(parsedPriceStr);
  }

  const previousPriceNum = parsePrice(trackedItemData.previous_price);
  const currentPriceNum = parsePrice(trackedItemData.current_price);
  const savings = previousPriceNum - currentPriceNum;

  const deleteItem = async () => {
    try {
      await deleteProduct(currentUser.uid, trackedItemData.id);
      await fetchTrackedItems();
      raiseSnackbar("info", "Item deleted", {
        vertical: "top",
        horizontal: "center",
      });
    } catch (error) {
      if (error instanceof Error) {
        raiseSnackbar("error", error.message, {
          vertical: "top",
          horizontal: "center",
        });
      }
    }
  };

  return (
    <ProductInfoContainer id="PRODUCT INFO CONTAINER">
      {/* hide if price didn't increase */}
      {/* {savings > 0 && ( */}
      <DecreasedPriceBoxDesktop>
        &nbsp;
        {savings}
        {trackedItemData.currency_sign}
        &nbsp; reduced
      </DecreasedPriceBoxDesktop>
      <TopContainerMobile>
        <DecreasedPriceBox>
          &nbsp;
          {savings}
          {trackedItemData.currency_sign}
          &nbsp; reduced
        </DecreasedPriceBox>
        <TrackTimeBoxMobile>
          <span>Track Time: </span>
          <span>8d.6hrs</span>
        </TrackTimeBoxMobile>
      </TopContainerMobile>

      <ProductInfoTop>
        <ThreeDotsIcon src="./img/threeDots.svg" alt="threeDotsIcon" />
        <Link
          href={trackedItemData.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ProductImage src={trackedItemData.image_url} alt="product image" />
        </Link>
      </ProductInfoTop>
      <ProductInfoBottom>
        <div className="top">
          <Link
            href={trackedItemData.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ProductTitle>{trackedItemData.title}</ProductTitle>
          </Link>
          <ProductDescription>{trackedItemData.description}</ProductDescription>
        </div>
        <div className="bottom">
          <DeleteProductButton onClick={deleteItem}>
            <CrossIcon src="/img/crossIcon.svg" alt="icon" />
            <span> Remove </span>
          </DeleteProductButton>
        </div>
      </ProductInfoBottom>
      <TrackTimeBoxDesktop>
        <span>Track Time : </span>
        <span>8d.6hrs</span>
      </TrackTimeBoxDesktop>
    </ProductInfoContainer>
  );
};

export default ProductInfo;
