import React from 'react';
import styled from 'styled-components';
import SubscriptionCard from './subComponents/SubscriptionCard';
import Colors from '../../constants/Colors';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const SubscriptionContainer = styled.div`
  width: 100%;
  padding-top: 35px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 60px;

  @media (min-width: 1501px) {
    width: 680px;
    padding-top: 105px;
    margin-left: 110px;

    align-items: flex-start;
  }
`;

const PremiumBadge = styled.span`
  background-color: #ffd700;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 10px;
`;

const FreeSubscriptionBenefits = [
  'Track up to 5 products',
  'Price checking once a day',
];
const PremiumSubscriptionBenefits = [
  'Track up to 200 products',
  'Price checking every 3 hours',
];

const SubscriptionContent = () => {
  const navigate = useNavigate();
  const { isPaid } = useAuth();

  const handleGoPremium = () => {
    navigate('/checkout');
  };

  return (
    <SubscriptionContainer>
      <SubscriptionCard
        title="Free"
        price="$0"
        benefitsList={FreeSubscriptionBenefits}
        actionButtonText="Free"
        actionButtonOnClick={() => console.log('free plan')}
        color={Colors.black1}
        buttonStyle={{ backgroundColor: '#F0F0F0', color: Colors.black1 }}
        disabled
      />
      <SubscriptionCard
        title={
          <>
            Premium ($4/mo)
            {isPaid && <PremiumBadge>Current Plan</PremiumBadge>}
          </>
        }
        price="$4"
        benefitsList={PremiumSubscriptionBenefits}
        actionButtonText="GO PREMIUM"
        actionButtonOnClick={handleGoPremium}
        color={Colors.primary}
        buttonStyle={{ backgroundColor: Colors.primary, color: 'white' }}
        disabled={isPaid}
      />
    </SubscriptionContainer>
  );
};

export default SubscriptionContent;
