import styled from "styled-components";
import Colors from "../../../constants/Colors";

import { Link } from "react-router-dom";

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #3b3b3b;
  text-align: center;
  margin-bottom: 28px;
`;

const SubTitleLight = styled(SubTitle)`
  font-weight: 400;
`;

const LinkText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${Colors.primary};
  margin-left: 7px;
  text-decoration: underline;

  a {
    font-size: 16px;
    font-weight: 600;
    color: ${Colors.primary};
    text-decoration: underline;
  }
`;

const LinkTextLight = styled(LinkText)`
  font-weight: 400;
`;

interface ISignFormSubtitle {
  type: "sign-in" | "sign-up" | "reset-password";
}

const SignFormSubtitle = ({ type }: ISignFormSubtitle) => {
  if (type === "sign-in") {
    return (
      <SubTitle>
        Don't have an account?
        <LinkText>
          <Link to="/signup">Sign up</Link>
        </LinkText>
      </SubTitle>
    );
  } else if (type === "sign-up") {
    return (
      <SubTitle>
        Already have an account?
        <LinkText>
          <Link to="/login">Sign in</Link>
        </LinkText>
      </SubTitle>
    );
  } else if (type === "reset-password") {
    return (
      <SubTitle>
        <LinkText>
          <Link to="/reset-password">Forgot password?</Link>
        </LinkText>
      </SubTitle>
    );
  } else {
    return null; // Return null if the type is not one of the expected values
  }
};

export {
  SignFormSubtitle as default,
  SubTitle,
  SubTitleLight,
  LinkText,
  LinkTextLight,
};
