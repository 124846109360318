import React, { useState } from "react";
import styled from "styled-components";
import Button from "../commonComponents/Button";
import TextField from "../commonComponents/TextField";
import { useAuth } from "../../contexts/AuthContext";

const MyProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding-bottom: 35px;

  @media (min-width: 1501px) {
    width: 680px;
    margin-left: 110px;
  }
`;

const FormGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: 1501px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const LabelTextFieldContainer = styled.div`
  width: 100%;
  max-width: 500px;
`;

const StyledLabel = styled.label`
  margin-bottom: 5px;
`;

const UserAvatar = styled.img`
  margin-bottom: 28px;
  margin-top: 50px;
  width: 147px;
  align-self: center;

  @media (min-width: 1501px) {
    margin-left: 0;
  }
`;

const InfoText = styled.p`
  color: red;
  font-weight: bold;
`;

const MyProfileContent = () => {
  const { currentUser, updateEmail, updatePassword } = useAuth();

  const isGoogleUser =
    currentUser.providerData?.[0]?.providerId === "google.com";

  const [newEmailVal, setNewEmailVal] = useState("");
  const [confirmEmailVal, setConfirmEmailVal] = useState("");
  const [newPasswordVal, setNewPasswordVal] = useState("");
  const [confirmPasswordVal, setConfirmPasswordVal] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleEmailUpdate = async () => {
    if (newEmailVal !== confirmEmailVal) {
      setEmailError("New email and confirm email do not match.");
      return;
    }

    try {
      setEmailError(""); // Clear any previous errors
      await updateEmail(newEmailVal);
      alert("Email updated successfully!");
    } catch (err: any) {
      if (err instanceof Error) {
        setEmailError("Failed to update email. " + err.message);
      } else {
        setEmailError("Failed to update email. " + JSON.stringify(err));
      }
    }
  };

  const handlePasswordUpdate = async () => {
    if (newPasswordVal !== confirmPasswordVal) {
      setPasswordError("New password and confirm password do not match.");
      return;
    }

    try {
      setPasswordError(""); // Clear any previous errors
      await updatePassword(newPasswordVal);
      alert("Password updated successfully!");
    } catch (err: any) {
      if (err instanceof Error) {
        setPasswordError("Failed to update password. " + err.message);
      } else {
        setPasswordError("Failed to update password. " + JSON.stringify(err));
      }
    }
  };

  return (
    <MyProfileContainer>
      {isGoogleUser && (
        <InfoText>
          Note: You can't change your email as you signed in with Google.
        </InfoText>
      )}
      {!isGoogleUser && (
        <>
          <UserAvatar src="/img/user-icon.svg" alt="user avatar" />

          <FormGroup>
            <LabelTextFieldContainer>
              <StyledLabel htmlFor="currentEmail">Current Email</StyledLabel>
              <TextField
                placeholder={currentUser.email}
                type="email"
                name="currentEmail"
                id="currentEmail"
                disabled
              />
            </LabelTextFieldContainer>

            <LabelTextFieldContainer>
              <StyledLabel htmlFor="newEmail">New Email</StyledLabel>
              <TextField
                type="email"
                name="newEmail"
                placeholder=""
                value={newEmailVal}
                onChange={(e) => setNewEmailVal(e.target.value)}
                error={emailError !== ""}
                errorMessage={emailError}
              />
            </LabelTextFieldContainer>

            <LabelTextFieldContainer>
              <StyledLabel htmlFor="confirmNewEmail">
                Confirm New Email
              </StyledLabel>
              <TextField
                placeholder=""
                type="email"
                name="confirmNewEmail"
                id="confirmNewEmail"
                value={confirmEmailVal}
                onChange={(e) => setConfirmEmailVal(e.target.value)}
              />
            </LabelTextFieldContainer>

            <Button type="lowkey" onClick={handleEmailUpdate}>
              Confirm Email Change
            </Button>
          </FormGroup>

          <FormGroup>
            <LabelTextFieldContainer>
              <StyledLabel htmlFor="newPassword">New Password</StyledLabel>
              <TextField
                type="password"
                placeholder=""
                name="newPassword"
                value={newPasswordVal}
                onChange={(e) => setNewPasswordVal(e.target.value)}
                error={passwordError !== ""}
                errorMessage={passwordError}
              />
            </LabelTextFieldContainer>

            <LabelTextFieldContainer>
              <StyledLabel htmlFor="confirmNewPassword">
                Confirm New Password
              </StyledLabel>
              <TextField
                placeholder=""
                type="password"
                name="confirmNewPassword"
                id="confirmNewPassword"
                value={confirmPasswordVal}
                onChange={(e) => setConfirmPasswordVal(e.target.value)}
              />
            </LabelTextFieldContainer>

            <Button type="lowkey" onClick={handlePasswordUpdate}>
              Confirm Password Change
            </Button>
          </FormGroup>

          {emailError && <p style={{ color: "red" }}>{emailError}</p>}
          {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
        </>
      )}
    </MyProfileContainer>
  );
};

export default MyProfileContent;
