import React from "react";
import styled from "styled-components";
import Colors from "../../../constants/Colors";

// THIS COMPONENT IS ONLY FOR DESKTOP DEVICES

interface ISettingsNavbar {
  activeTab: "myProfile" | "subscription";
  setActiveTab: React.Dispatch<
    React.SetStateAction<"myProfile" | "subscription">
  >;
}

const NavbarContainer = styled.div`
  display: flex;
  gap: 0px;
  justify-content: flex-start;
`;

const NavbarTabWrapper = styled.div<{ $isActive: boolean }>`
  width: 321px;
  height: 110px;
  background-color: ${(props) => (props.$isActive ? "white" : "inherit")};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const NavbarTab = styled.div<{ $isActive: boolean }>`
    width: 241px;
    height: 60px;
    border: 2px solid; 
    border-radius: 2px; 
    font-weight: 500;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-color: ${(props) =>
      props.$isActive ? Colors.primary : Colors.black1};
    color= ${(props) => (props.$isActive ? Colors.primary : Colors.black1)};
    background-color: ${(props) =>
      props.$isActive ? Colors.orangeLight : Colors.orangeLight};
  
    `;

const SettingsNavbar = ({ activeTab, setActiveTab }: ISettingsNavbar) => {
  return (
    <NavbarContainer>
      <NavbarTabWrapper
        $isActive={activeTab === "myProfile"}
        onClick={() => setActiveTab("myProfile")}
      >
        <NavbarTab $isActive={activeTab === "myProfile"}>My profile</NavbarTab>
      </NavbarTabWrapper>
      <NavbarTabWrapper
        $isActive={activeTab === "subscription"}
        onClick={() => setActiveTab("subscription")}
      >
        <NavbarTab $isActive={activeTab === "subscription"}>
          Subscription
        </NavbarTab>
      </NavbarTabWrapper>
    </NavbarContainer>
  );
};

export default SettingsNavbar;
