import React, { useState } from "react";
import SignFormPaper from "./SignFormPaper";
import SignFormTitle from "./SignFormTitle";
import TextField from "../../commonComponents/TextField";
import Button from "../../commonComponents/Button";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { useAuth } from "../../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { LinkText, SubTitleLight } from "./SignFormSubtitle";

const defaultFormFields = {
  email: "",
};

const ForgotPasswordForm = () => {
  const { resetPassword } = useAuth();
  const { raiseSnackbar } = useSnackbar();
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email } = formFields;
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      await resetPassword(email);
      raiseSnackbar(
        "success",
        "Check your inbox for further instructions",
        {
          vertical: "top",
          horizontal: "center",
        },
        5000
      );
    } catch (error: any) {
      raiseSnackbar(
        "warning",
        "Failed to reset password",
        {
          vertical: "top",
          horizontal: "center",
        },
        5000
      );
    }
    setLoading(false);
  };

  return (
    <SignFormPaper>
      <SignFormTitle>Forgot Password</SignFormTitle>
      <TextField
        type="email"
        name="email"
        placeholder="Email address"
        value={email}
        onChange={handleChange}
        style={{ marginBottom: "30px" }}
      />
      <Button type="email" onClick={handleResetPassword} disabled={loading}>
        Reset Password
      </Button>
      <SubTitleLight style={{ marginTop: "20px" }}>
        Back to
        <LinkText>
          <Link to="/login">Sign In </Link>
        </LinkText>
      </SubTitleLight>
    </SignFormPaper>
  );
};

export default ForgotPasswordForm;
