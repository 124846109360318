import styled from "styled-components";
import { useHome } from "../../contexts/HomeContext";
import ProductCard from "./ProductCard";

// get all the tracked products from the context and render them here
// for now let's just use some dummy data

const ProductCardContainer = styled.div`
  @media (max-width: 1500px) {
    margin-bottom: 33px;
  }

  @media (min-width: 1501px) {
    margin-bottom: 23px;
  }
`;

const TrackedItems = () => {
  const { trackedItems } = useHome();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      key={trackedItems.length}
    >
      {trackedItems.map((trackedItem, id) => (
        <ProductCardContainer key={trackedItem.id}>
          <ProductCard trackedItemData={trackedItem} />
        </ProductCardContainer>
      ))}
    </div>
  );
};

export default TrackedItems;
