// HomeContext.tsx

import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../utils/firebase';
import { doc, getDoc } from 'firebase/firestore';

interface IHomeContext {
  trackedItems: ITrackedItem[];
  fetchTrackedItems: () => void;
}

const HomeContext = createContext<IHomeContext | undefined>(undefined);

export type ITrackedItem = {
  id: string;
  title: string;
  description: string;
  url: string;
  product_image_url: string;
  current_price: string;
  previous_price: string;
  currency_sign: string;
  image_url: string;
};

const HomeProvider = ({ children }: { children: ReactNode }) => {
  const [trackedItems, setTrackedItems] = useState<ITrackedItem[]>([]);
  const { currentUser } = useAuth(); // Assuming you have this context available

  const fetchTrackedItems = async () => {
    try {
      const userDocRef = doc(db, 'users', currentUser?.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const productsToTrack = userDocSnap.data()?.products_to_track || [];

        const fetchedTrackedItems: ITrackedItem[] = [];

        for (let product of productsToTrack) {
          const productDocRef = doc(db, 'products', product.product_id);
          const productDocSnap = await getDoc(productDocRef);

          if (productDocSnap.exists()) {
            const productDetails = productDocSnap.data();

            fetchedTrackedItems.push({
              id: product.product_id,
              title: productDetails?.title,
              description: '', // This might need to be fetched or decided upon.
              url: productDetails?.url,
              product_image_url: '', // Add logic to fetch this or decide upon.
              current_price: productDetails?.price,
              previous_price: product.starting_price,
              currency_sign: productDetails?.currency_sign,
              image_url: productDetails?.image_url,
            });
          }
        }

        const reversedTrackedItems = fetchedTrackedItems.reverse(); // Reversing the fetchedTrackedItems array

        setTrackedItems(reversedTrackedItems);
      }
    } catch (error) {
      console.error('Error fetching tracked items:', error); // Logging any error that occurs
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchTrackedItems();
    }
  }, [currentUser]);

  useEffect(() => {
    console.log(trackedItems, 'trackedItems here');
  }, [trackedItems]);

  return (
    <HomeContext.Provider value={{ trackedItems, fetchTrackedItems }}>
      {children}
    </HomeContext.Provider>
  );
};

const useHome = () => {
  const context = useContext(HomeContext);
  if (!context) {
    throw new Error('useHome must be used within a HomeProvider');
  }
  return context;
};

export { HomeProvider, useHome };
