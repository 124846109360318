import React from "react";
import SignPageContainer from "../components/authenticationComponents/subComponents/SignFormContainer";
import ForgotPasswordForm from "../components/authenticationComponents/subComponents/ForgotPasswordForm";

const ForgotPasswordPage = () => {
  return (
    <SignPageContainer>
      <ForgotPasswordForm />
    </SignPageContainer>
  );
};

export default ForgotPasswordPage;
