import React, { createContext, useContext, useState, ReactNode } from "react";
import {
  Alert as MUIAlert,
  Typography,
  IconButton,
  Snackbar,
} from "@mui/material/";
import { Icon } from "@iconify/react";
import baselineClose from "@iconify/icons-ic/baseline-close";

function Alert({
  alert,
  setAlert,
  type,
  message,
  position,
  autoHideDuration,
}: any) {
  return (
    <Snackbar
      anchorOrigin={position}
      open={alert}
      autoHideDuration={autoHideDuration}
      onClose={() => setAlert(false)}
    >
      <MUIAlert
        severity={type}
        style={{
          minWidth: "420px",
          marginLeft: "auto",
          marginRight: "auto",
          top: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            minWidth: "420px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "18px",
              letterSpacing: "-0.3px",
            }}
          >
            {message}
          </Typography>
          <IconButton
            size="small"
            style={{
              marginLeft: "auto",
            }}
            onClick={() => setAlert(false)}
          >
            <Icon
              style={{ color: "grey", fontSize: "16px" }}
              icon={baselineClose}
            />
          </IconButton>
        </div>
      </MUIAlert>
    </Snackbar>
  );
}

interface IRaiseSnackbar {
  newType?: string;
  newMessage?: string;
  newPosition?: {
    vertical: "top" | "bottom";
    horizontal: "center" | "left" | "right";
  };
  newAutoHideDuration?: number;
}

interface ISnackbarContext {
  raiseSnackbar: any;
}

// @ts-ignore
const SnackbarContext = createContext<ISnackbarContext>();

const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [alert, setAlert] = useState(false);
  const [type, setType] = useState("error");
  const [position, setPosition] = useState({
    vertical: "top",
    horizontal: "center",
  });
  const [message, setMessage] = useState("");
  const [autoHideDuration, setAutoHideDuration] = useState(null);

  function raiseSnackbar(
    newType = "error",
    newMessage = "",
    newPosition = {
      vertical: "top",
      horizontal: "center",
    },
    newAutoHideDuration = null
  ) {
    setAlert(true);
    setMessage(newMessage);
    setType(newType);
    setPosition(newPosition);
    setAutoHideDuration(newAutoHideDuration);
  }
  return (
    <SnackbarContext.Provider
      value={{
        raiseSnackbar,
      }}
    >
      <Alert
        alert={alert}
        setAlert={setAlert}
        type={type}
        message={message}
        position={position}
        autoHideDuration={autoHideDuration}
      />
      {children}
    </SnackbarContext.Provider>
  );
};

const useSnackbar = () => useContext(SnackbarContext);

export { SnackbarProvider, useSnackbar };
