import styled from 'styled-components';

import TrackNewProductForm from '../components/homeComponents/TrackNewProductForm';
import TrackedItems from '../components/homeComponents/TrackedItems';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  positoin: relative;

  @media (max-width: 1500px) {
    width: 95%;
    margin: auto;
    top: 78px;
  }

  @media (min-width: 1501px) {
    margin-left: 54px;
    margin-right: 50px;
  }
`;

const TrackNewProductFormContainer = styled.div`
  @media (max-width: 1500px) {
    margin-top: 23px;
    margin-bottom: 25px;
  }

  @media (min-width: 1501px) {
    margin-top: 67px;
    margin-bottom: 0px;
  }
`;

const ActualContentContainer = styled.div`
  margin-top: 60px;
`;

export const Home = () => {
  return (
    <HomeContainer>
      <TrackNewProductFormContainer>
        <TrackNewProductForm />
      </TrackNewProductFormContainer>
      <ActualContentContainer>
        <TrackedItems />
      </ActualContentContainer>
    </HomeContainer>
  );
};

export default Home;
