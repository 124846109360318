import React from "react";
import SignFormPaper from "./subComponents/SignFormPaper";
import SignFormTitle from "./subComponents/SignFormTitle";
import Button from "../commonComponents/Button";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../contexts/SnackbarContext";
import { useAuth } from "../../contexts/AuthContext";
import SignFormSubtitle from "./subComponents/SignFormSubtitle";

const SignInForm = () => {
  const { raiseSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { loginWithGoogle, loginWithApple } = useAuth();

  const handleSigninWithGoogle = async () => {
    try {
      await loginWithGoogle();
      navigate("/");
    } catch {
      raiseSnackbar(
        "error",
        "An error occurred with Google sign in. Please try again.",
        {
          vertical: "top",
          horizontal: "center",
        },
        5000
      );
    }
  };

  const handleSigninWithApple = async () => {
    try {
      await loginWithApple();
      navigate("/");
    } catch {
      raiseSnackbar(
        "error",
        "An error occurred with Apple sign in. Please try again.",
        {
          vertical: "top",
          horizontal: "center",
        },
        5000
      );
    }
  };

  return (
    <SignFormPaper>
      <SignFormTitle> Sign In </SignFormTitle>
      <SignFormSubtitle type="sign-in" />
      <Button
        type="google"
        onClick={handleSigninWithGoogle}
        style={{ marginBottom: "10px" }}
      >
        Sign in with Google
      </Button>
      {/* <Button type="apple" onClick={handleSigninWithApple}>
        Sign in with Apple
      </Button> */}
    </SignFormPaper>
  );
};

export default SignInForm;
