import styled from "styled-components";
import ProductInfo from "./subComponents/ProductInfo";
import ProductDetails from "./subComponents/ProductDetails";
import { ITrackedItem } from "../../contexts/HomeContext";
import { relative } from "path";

interface IProductCard {
  trackedItemData: ITrackedItem;
}

const sizes = {
  desktop: 1501,
  mobile: 1500,
};

const media = {
  desktop: `(min-width: ${sizes.desktop}px)`,
  mobile: `(max-width: ${sizes.mobile}px)`,
};

const ProductContainer = styled.div`
  position: relative;

  @media (max-width: 1500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 1501px) {
    width: 100%;
    display: flex;
    gap: 48px;
  }
`;

const DecreasedPriceBoxDesktop = styled.span`
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  background-color: #ffd2d2;
  width: auto;
  padding: 2px 5px 2px 0px;
  color: #ff6565;
  position: absolute;
  top: 18px;
  left: 21px;

  @media ${media.mobile} {
    display: none;
  }
`;

const ProductCard = ({ trackedItemData }: IProductCard) => {
  function parsePrice(priceStr: string) {
    // Replace periods with nothing and commas with decimal points
    const parsedPriceStr = priceStr.replace(".", "").replace(",", ".");

    // Convert the string to a float
    return parseFloat(parsedPriceStr);
  }

  const previousPriceNum = parsePrice(trackedItemData.previous_price);
  const currentPriceNum = parsePrice(trackedItemData.current_price);
  const savings = previousPriceNum - currentPriceNum;

  return (
    <ProductContainer id="product card">
      {/* <DecreasedPriceBox>
        &nbsp;
        <img
          src="./img/price-reduction-icon.svg"
          alt="price reduction icon"
          style={{ marginRight: "2px", height: "13px" }}
        />
        {savings}
        {trackedItemData.currency_sign}
        &nbsp; reduced
      </DecreasedPriceBox> */}
      <ProductInfo trackedItemData={trackedItemData} />
      <ProductDetails trackedItemData={trackedItemData} />
    </ProductContainer>
  );
};

export default ProductCard;
