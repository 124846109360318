import React, { createContext, useContext, ReactNode } from "react";

interface ISettingsContext {}

const SettingsContext = createContext<ISettingsContext | undefined>(undefined);

const SettingsProvider = ({ children }: { children: ReactNode }) => {
  return (
    <SettingsContext.Provider value={{}}>{children}</SettingsContext.Provider>
  );
};

const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};

export { SettingsProvider, useSettings };
