import React from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import Button from '../../commonComponents/Button';

interface ISubscriptionCard {
  title: React.ReactNode; // Changed to ReactNode to allow JSX
  price: string;
  benefitsList: string[];
  actionButtonText: string;
  actionButtonOnClick: () => void;
  color: string;
  buttonStyle?: React.CSSProperties;
  disabled?: boolean;
}

const SubscriptionCardContainer = styled.div`
  width: 300px;
  padding: 35px 50px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  min-height: 340px;
  justify-content: space-between;
`;

const SubscriptionCardTitle = styled.div<{ $color: string }>`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  width: 95%;
  margin: auto;
  color: ${(props) => props.$color};
  padding-bottom: 7px;
  border-bottom: 2px solid #e6e6e6;
  margin-bottom: 28px;
`;

const BenefitRow = styled.div<{ $color: string }>`
  display: flex;
  align-items: center;
  gap: 13px;
  color: ${(props) => props.$color};
`;

const SubscriptionCard: React.FC<ISubscriptionCard> = ({
  title,
  price,
  benefitsList,
  actionButtonText,
  actionButtonOnClick,
  color,
  buttonStyle,
  disabled,
}) => {
  return (
    <SubscriptionCardContainer>
      <div>
        <SubscriptionCardTitle $color={color}>{title}</SubscriptionCardTitle>
        {benefitsList.map((benefit) => (
          <BenefitRow $color={color} style={{ marginBottom: '10px' }}>
            <Icon icon="material-symbols:check" color={color} />
            <span style={{ color: 'black' }}>{benefit}</span>
          </BenefitRow>
        ))}
      </div>
      <Button
        type="subscription"
        onClick={actionButtonOnClick}
        style={buttonStyle}
        disabled={disabled}
      >
        {actionButtonText}
      </Button>
    </SubscriptionCardContainer>
  );
};

export default SubscriptionCard;
