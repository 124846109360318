import React from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import { markUserAsPaid } from '../../backend_api';

const FormContainer = styled.form`
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
`;

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { currentUser } = useAuth();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    // Determine the return URL based on the environment
    const returnUrl =
      process.env.NODE_ENV === 'production'
        ? 'https://app.amapify.com/success'
        : 'https://app.amapify.com/success';

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    if (result.error) {
      alert(`Payment failed: ${result.error.message}`);
    } else {
      markUserAsPaid(currentUser.uid);
      alert('Payment succeeded!');
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <PaymentElement />
      <SubmitButton disabled={!stripe}>Submit</SubmitButton>
    </FormContainer>
  );
};

export default CheckoutForm;
