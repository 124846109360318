import React from "react";
import styled from "styled-components";
import Colors from "../../../constants/Colors";
import { Icon } from "@iconify/react";

interface ICollapseTab {
  title: string;
  activeTab: "myProfile" | "subscription";
  isActiveTab: boolean;
  setActiveTab: React.Dispatch<
    React.SetStateAction<"myProfile" | "subscription">
  >;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const sizes = {
  desktop: 1501,
  mobile: 1500,
};

const media = {
  desktop: `(min-width: ${sizes.desktop}px)`,
  mobile: `(max-width: ${sizes.mobile}px)`,
};

const CollapseTabContainer = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  max-width: 1000px;
  padding: 32px 0px;
  border-radius: 8px;

  background-color: ${(props) => (props.$isOpen ? "white" : "inherit")};
`;

const HeaderContainer = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  max-width: 1000px;
  border: 2px solid; 
  border-radius: 8px; 
  display: flex;
  justify-content: space-between;
  border-color: ${(props) => (props.$isOpen ? Colors.primary : Colors.black1)};
  color= ${(props) => (props.$isOpen ? Colors.primary : Colors.black1)};
  background-color: ${(props) =>
    props.$isOpen ? Colors.orangeLight : Colors.orangeLight};

  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 13px 30px;
  margin-bottom: 28px;
`;

const CollapseTab = ({
  title,
  activeTab,
  isActiveTab,
  setActiveTab,
  children,
  style,
}: ICollapseTab) => {
  const toggleOpen = () => {
    if (activeTab === "myProfile") {
      setActiveTab("subscription");
    }
    if (activeTab === "subscription") {
      setActiveTab("myProfile");
    }
  };

  return (
    <CollapseTabContainer $isOpen={isActiveTab} style={{ ...style }}>
      <div style={{ width: "80%", margin: "auto" }}>
        <HeaderContainer $isOpen={isActiveTab} onClick={toggleOpen}>
          <span> {title} </span>
          <Icon
            icon={
              isActiveTab ? "ion:arrow-up-outline" : "ion:arrow-down-outline"
            }
            width={20}
            color={isActiveTab ? Colors.primary : Colors.black1}
          />
        </HeaderContainer>
        {isActiveTab && children}
      </div>
    </CollapseTabContainer>
  );
};

export default CollapseTab;
