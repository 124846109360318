import styled from "styled-components";

const SignFormTitle = styled.div`
  font-size: 42px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 17px;
`;

export default SignFormTitle;
