import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Confetti from 'react-dom-confetti';
import { checkPaymentStatus, markUserAsPaid } from '../backend_api'; // Import the API functions
import { useAuth } from '../contexts/AuthContext';

const PaymentSuccessPage = () => {
  const [confetti, setConfetti] = useState(false);
  const [countdown, setCountdown] = useState(5); // 5-second countdown
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { currentUser } = useAuth();

  const paymentIntent = queryParams.get('payment_intent');
  const redirectStatus = queryParams.get('redirect_status');

  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 200,
    dragFriction: 0.12,
    duration: 8000,
    stagger: 3,
    width: '10px',
    height: '10px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };

  useEffect(() => {
    let paymentCheckInterval: NodeJS.Timeout;
    let timer: NodeJS.Timeout;

    const fetchData = async () => {
      if (redirectStatus === 'succeeded' && paymentIntent) {
        // Added null check for paymentIntent
        // Poll the server to check the payment status
        paymentCheckInterval = setInterval(async () => {
          const isPaid = await checkPaymentStatus(paymentIntent); // Assume this function checks the payment status on the server
          if (isPaid) {
            clearInterval(paymentCheckInterval);
            console.log(currentUser, 'current user hea');
            markUserAsPaid(currentUser.uid); // Pass the required argument
            setConfetti(true);

            timer = setInterval(() => {
              setCountdown((prevCount) => prevCount - 1);
            }, 1000);

            setTimeout(() => {
              navigate('/');
            }, 5000); // Redirect after 5 seconds
          }
        }, 2000); // Check every 2 seconds
      }
    };

    fetchData();

    return () => {
      clearInterval(paymentCheckInterval);
      clearInterval(timer);
    };
  }, [navigate, redirectStatus, paymentIntent]);

  return (
    <div
      style={{
        position: 'relative',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Confetti active={confetti} config={config} />
      {redirectStatus === 'succeeded' ? (
        <>
          <h1 style={{ fontSize: '3rem' }}>Welcome to Amapify Premium!</h1>
          <p>You can now track up to 200 products!</p>
          <p>Redirecting in {countdown} seconds...</p>
        </>
      ) : (
        <h1 style={{ fontSize: '3rem' }}>Payment failed. Please try again.</h1>
      )}
    </div>
  );
};

export default PaymentSuccessPage;
