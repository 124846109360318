import React, { useState, useEffect } from 'react';
import CheckoutForm from '../components/settingsComponents/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createPaymentIntent } from '../backend_api';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

interface IStripeData {
  clientSecret: string;
  paymentIntentId: string;
}

const CheckoutPage = () => {
  const [stripeData, setStripeData] = useState<IStripeData>();
  const { currentUser } = useAuth();

  useEffect(() => {
    createPaymentIntent(currentUser?.uid, 400, 'usd')
      .then((data) => {
        setStripeData(data);
      })
      .catch((error) => {
        console.error('Failed to fetch client secret:', error);
      });
  }, []);

  const stripePromise = loadStripe(
    'pk_live_51Nz21iIA4DHKR8am9kTgQtuvmxqksqopgBYRkiuDAhaIACumiBFxL7rVmNXXQ0VZL2dKEzsKqb6nNYEQSWBriFz900BaTF052g'
  );

  const options = {
    clientSecret: stripeData?.clientSecret as string,
  };

  return (
    <CheckoutContainer>
      {stripeData?.clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      ) : (
        <p>Loading...</p>
      )}
    </CheckoutContainer>
  );
};

export default CheckoutPage;
