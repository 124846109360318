import React from "react";
import SignPageContainer from "../components/authenticationComponents/subComponents/SignFormContainer";
import SignInForm from "../components/authenticationComponents/SignInForm";

const SignInPage = () => {
  return (
    <SignPageContainer>
      <SignInForm />
    </SignPageContainer>
  );
};

export default SignInPage;
