import styled from "styled-components";

const SignPageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #3b3b3b;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SignPageContainer;
