import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Icon } from "@iconify/react";

const StyledNav = styled.nav`
  background-color: #ffffff;
  border: 1px solid #707070;
  position: fixed;
  top: 0;
  z-index: 5;

  @media (max-width: 1500px) {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 7px;
  }

  @media (min-width: 1501px) {
    width: 264px;
    height: 100vh;
    padding-top: 224px;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #3b3b3b;

  &.active {
    color: #fcaf17;
  }

  @media (max-width: 1500px) {
    width: 50%;
    text-align: center;
  }
`;

const NavItem = styled.li`
  font-family: "Poppins";
  font-weight: 500;
  list-style: none;
  font-size: 1.375em;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 59px;
  display: block;

  i,
  svg {
    vertical-align: middle;
    margin-right: 8px;
  }

  @media (max-width: 1500px) {
    i,
    svg {
      display: none;
    }
  }

  @media (min-width: 700px) {
    i,
    svg {
      display: inline-block;
    }
  }
`;

const Navbar = () => {
  return (
    <StyledNav>
      <StyledNavLink to="/">
        <NavItem>
          <Icon icon="fa-solid:border-all" />
          Home
        </NavItem>
      </StyledNavLink>
      {/* <StyledNavLink to="/Settings">
        <NavItem>
          <Icon icon="fa:cog" />
          Settings
        </NavItem>
      </StyledNavLink> */}
    </StyledNav>
  );
};

export default Navbar;
