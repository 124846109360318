import styled from "styled-components";

const SignFormPaper = styled.div`
  width: 664px;
  padding-top: 58px;
  padding-bottom: 99px;
  padding: 58px 99px 99px 99px;
  background-color: white;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default SignFormPaper;
