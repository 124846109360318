import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router';
import { useNavigate } from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute';
import { useAuth } from './contexts/AuthContext';
import SignUpPage from './pages/SignUpPage';
import SignInPage from './pages/SignInPage';
import VerifyEmailPage from './pages/VerifyEmailPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import Home from './pages/HomePage';
import SettingsPage from './pages/SettingsPage';
import Navbar from './Navbar';
import Colors from './constants/Colors';
import styled from 'styled-components';
import CheckoutPage from './pages/CheckoutPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';

const ContentWrapper = styled.div`
  height: calc(100vh - 83px);
  overflow-y: scroll;
  padding-bottom: 150px;

  @media (max-width: 1500px) {
    margin-top: 83px;
  }

  @media (min-width: 1501px) {
    margin-left: 264px;
    height: calc(100vh);
  }

  /* Width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f5f5f5;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fcaf17;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fcaf17;
  }
`;

const App = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.emailVerified === false) {
      navigate('/verify');
    }
  }, [currentUser?.emailVerified]);

  return (
    <div
      className="App"
      style={{
        height: '100vh',
        width: '100%',
        backgroundColor: Colors.backgroundGrey,
        overflow: 'hidden',
      }}
    >
      <Routes>
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Navbar />
              <Routes>
                <Route
                  path="/"
                  element={
                    <ContentWrapper style={{ overflowY: 'scroll' }}>
                      <Home />
                    </ContentWrapper>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <ContentWrapper
                      style={{
                        overflowY: 'scroll',
                        backgroundColor: Colors.backgroundGrey,
                      }}
                    >
                      <SettingsPage />
                    </ContentWrapper>
                  }
                />
                <Route
                  path="success"
                  element={
                    <ContentWrapper
                      style={{
                        overflowY: 'scroll',
                        backgroundColor: Colors.backgroundGrey,
                      }}
                    >
                      <PaymentSuccessPage />
                    </ContentWrapper>
                  }
                />
              </Routes>
            </PrivateRoute>
          }
        />
        <Route path="signup" element={<SignUpPage />} />
        <Route path="login" element={<SignInPage />} />
        <Route path="verify" element={<VerifyEmailPage />} />
        <Route path="reset-password" element={<ForgotPasswordPage />} />
        <Route path="checkout" element={<CheckoutPage />} />
      </Routes>
    </div>
  );
};

export default App;
