/* global chrome */

import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../utils/firebase";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateEmail as updateEmailFirebase,
  updatePassword as updatePasswordFirebase,
  sendEmailVerification as sendEmailVerificationFirebase,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [isPaid, setIsPaid] = useState(false);
  const [loading, setLoading] = useState(true);
  const provider = new GoogleAuthProvider();

  const getIsPaid = async (uid) => {
    const userDocRef = doc(db, "users", uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      setIsPaid(userData.is_paid);
    } else {
      console.error("No such user!");
    }
  };

  useEffect(() => {
    if (currentUser) {
      getIsPaid(currentUser.uid);
    }
  }, [currentUser]);

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  const signUpWithGoogle = () => {
    return signInWithPopup(auth, provider).then((result) => {
      return result.user.getIdToken().then((idToken) => {
        // Save token in local storage
        localStorage.setItem("authToken", idToken);
        // Also save token in Chrome's local storage
        if (chrome && chrome.storage) {
          chrome.storage.local.set({ authToken: idToken }, () => {
            // After setting the authToken, send a message to the extension
            if (chrome.runtime.lastError) {
              console.error(chrome.runtime.lastError);
            } else {
              chrome.runtime.sendMessage({
                type: "AUTH_TOKEN_SET",
                authToken: idToken,
              });
            }
          });
        }
      });
    });
  };

  const loginWithGoogle = () => {
    console.log("Attempting to log in with Google");
    return signInWithPopup(auth, provider)
      .then((result) => {
        console.log("Received sign in result:", result);
        return result.user.getIdToken().then((idToken) => {
          console.log("ID Token:", idToken);
          // Save token in local storage
          localStorage.setItem("authToken", idToken);
          console.log("Saved ID token to localStorage");
          // Also save token in Chrome's local storage
          if (chrome && chrome.storage) {
            console.log("Attempting to save ID token to chrome.storage.local");
            chrome.storage.local.set({ authToken: idToken }, () => {
              // After setting the authToken, send a message to the extension
              if (chrome.runtime.lastError) {
                console.error(
                  "Error saving to chrome.storage.local:",
                  chrome.runtime.lastError
                );
              } else {
                console.log("Saved ID token to chrome.storage.local");
                chrome.runtime.sendMessage({
                  type: "AUTH_TOKEN_SET",
                  authToken: idToken,
                });
              }
            });
          }
        });
      })
      .catch((error) => {
        console.error("Failed to log in with Google:", error);
      });
  };

  function logout() {
    return signOut(auth).then(() => {
      // Remove the token from local storage
      localStorage.removeItem("authToken");
      // Also remove the token from Chrome's local storage
      if (chrome && chrome.storage) {
        chrome.storage.local.remove(["authToken"], () => {
          // After removing the authToken, send a message to the extension
          if (chrome.runtime.lastError) {
            console.error(chrome.runtime.lastError);
          } else {
            chrome.runtime.sendMessage({ type: "AUTH_TOKEN_REMOVED" });
          }
        });
      }
    });
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmail(email) {
    return updateEmailFirebase(currentUser, email);
  }

  function updatePassword(password) {
    return updatePasswordFirebase(currentUser, password);
  }

  const sendVerificationEmail = () => {
    return sendEmailVerificationFirebase(currentUser);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    isPaid,
    login,
    loginWithGoogle,
    signup,
    signUpWithGoogle,
    sendVerificationEmail,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
