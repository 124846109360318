import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CollapseTab from '../components/settingsComponents/subComponents/CollapseTab';
import SettingsNavbar from '../components/settingsComponents/subComponents/SettingsNavbar';

import MyProfileContent from '../components/settingsComponents/MyProfileContent';
import SubscriptionContent from '../components/settingsComponents/SubscriptionContent';

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  position: relative;
  top: 78px;
  min-height: 100%;

  @media (max-width: 1000px) {
    margin: auto;
  }

  @media (min-width: 1301px) {
    width: 70%;
    margin: auto;
  }

  @media (min-width: 1501px) {
    width: 70%;
    margin: none;
    margin-left: 104px;
  }
`;

const ContentContainer = styled.div`
  background-color: white;
  flex-grow: 1;
`;

export const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState<'myProfile' | 'subscription'>(
    'myProfile'
  );

  // State for desktop width
  const [isDesktopWidth, setIsDesktopWidth] = useState(
    window.innerWidth > 1000
  );

  // Effect for updating isDesktopWidth state
  useEffect(() => {
    const handleResize = () => setIsDesktopWidth(window.innerWidth > 1000);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <SettingsContainer>
      {isDesktopWidth ? (
        <>
          <SettingsNavbar activeTab={activeTab} setActiveTab={setActiveTab} />
          <ContentContainer style={{}}>
            {activeTab === 'myProfile' && <MyProfileContent />}
            {activeTab === 'subscription' && <SubscriptionContent />}
          </ContentContainer>
        </>
      ) : (
        <>
          <CollapseTab
            title="My profile"
            activeTab={activeTab}
            isActiveTab={activeTab === 'myProfile'}
            setActiveTab={setActiveTab}
          >
            <MyProfileContent />
          </CollapseTab>
          <CollapseTab
            title="Subscription"
            activeTab={activeTab}
            isActiveTab={activeTab === 'subscription'}
            setActiveTab={setActiveTab}
          >
            <SubscriptionContent />
          </CollapseTab>
        </>
      )}
    </SettingsContainer>
  );
};

export default SettingsPage;
